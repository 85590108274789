<script>
import errorHandler from '../../helpers/errorHandler';
import { required } from "vuelidate/lib/validators";

/**
 * Starter component
 */
export default {
  props:{
    stud_id:{
      type: String,
      required: true
    }
  },
  data() {
    return {
      submitted: false,
      loading: false,
      form:{
        stud_id: null,
        stud_grade: null,
        quiz_id: null
      },
      quizError: null,

    };
  },
  async created(){
    await this.$store.dispatch('quiz/getAllQuizesByLecture',{id:this.$route.params.chapter,type:'quiz'} );
    this.form.stud_id = this.stud_id;
  },  
  computed:{
    quizes(){
      return this.$store.getters['quiz/allQuizes'];
    }
  },
  methods:{
    clearSelectError(){
      this.quizError =null;
    },
    validate(){
      if(this.form.quiz_id){
        this.quizError = true;
      }else{
        this.quizError = false;
      }
      return this.quizError ;
    },
    async assign() {
      this.submitted = true;
      this.loading = true;
      await this.$v.$touch();
      let check = await this.validate()
      if (this.$v.$invalid || !check) {
        this.loading = false;
        return;
      } else {
        this.$store.dispatch("quiz/answerQuizByID", this.form).then( token =>{
              errorHandler.methods.successful('Quiz score assigned !!')
              this.form.stud_id = null;
              this.form.stud_grade = null;
              this.submitted = null;
              this.quizError = null;
            }).catch((error) => {
              if(error.response.data.detail["error message"]){
                errorHandler.methods.error(error)
              }else{
                errorHandler.methods.errorMessage(error)
              }
        });
        this.loading = false;
      }
    },
  },
  validations: {
    form:{
      stud_id: {
        required,
      },
      stud_grade: {
        required,
      },
      quiz_id: {
        required,
      },
    }
  },
};
</script>

<template>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <form action="#" @submit.prevent="assign">
              <div class="form-group mb-1">
                <label for="Codeid">Student ID</label>
                <input
                  class="form-control"
                  v-model="form.stud_id"
                  disabled
                  type="text"
                  id="codeid"
                  placeholder="Enter student ID"
                  :class="{ 'is-invalid': submitted && $v.form.stud_id.$error }"
                />
                <div
                  v-if="submitted && $v.form.stud_id.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.stud_id.required">Student ID is required.</span>
                </div>
              </div>
              <div class="form-group mb-1">
                <label for="grade">Grade</label>
                <input
                  class="form-control"
                  v-model="form.stud_grade"
                  type="text"
                  id="grade"
                  placeholder="Enter grade"
                  :class="{ 'is-invalid': submitted && $v.form.stud_grade.$error }"
                />
                <div
                  v-if="submitted && $v.form.stud_grade.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.stud_grade.required">Grade is required.</span>
                </div>
              </div>
              <b-form-group label="Quiz" label-for="input-C" >
                <b-form-select  v-model="form.quiz_id" :options="this.quizes" :state="quizError" @change="clearSelectError" text-field="name" value-field="id"></b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
              </b-form-group>
              <div class="form-group mb-0 text-center">
                <button :disabled="loading" class="btn btn-primary btn-block" type="submit">
                  Assign
                </button>
                <button class="btn btn-danger btn-block" @click="$emit('cancel-grade');">
                  Cancel
                </button>
              </div>
            </form>

           
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
</template>
